import 'bootstrap34';
import 'bootstrap34/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'webf';
import 'webf/dist/webf.bundle.css';

import '../js/app/smart/app.js';
import '../styles/bootstrap_extension.scss';
import '../styles/bootstrap_pull.css';
import '../styles/main.scss';
import '../styles/smart.webf.scss';
import '../styles/smart_client.scss';

$('.handle-menu-edl').webfDropdownmenu({
    menu: $('.menu-edl'),
    behavior: 'menu-toggle',
    delayClose: 400,
    position: {
        my: 'left top',
        at: 'left bottom',
    }
});

$('.handle-menu-diagnostics').webfDropdownmenu({
    menu: $('.menu-diagnostics'),
    behavior: 'menu-toggle',
    delayClose: 400,
    position: {
        my: 'left top',
        at: 'left bottom',
    }
});

$('.handle-menu-edl, .handle-menu-diagnostics').on('click', function () {
    if ($(window).width() < 768) {
        $(this).closest('li').toggleClass('menu-open');
    }
});
