import $ from 'jquery';
import refreshWebf from './refresh_webf.js'

function viewport() {
    // Source : http://andylangton.co.uk/blog/development/get-viewport-size-width-and-height-javascript
    var e = window,
        a = 'inner';
    if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
    }

    return {
        width: e[a + 'Width'],
        height: e[a + 'Height'],
    };
}

refreshWebf();

var widget;
$('.flash-messages').each(function () {
    var type = $(this).data('type');

    $(this).webfAlert({
        alertType: type,
        autoClose: true,
        delayClose: 5000,
        durationClose: 1000,
    });
});

$('.sidebar').webfScrollbar({
    stopScrollingOnEnd: true,
});

$('.sidebar-toggle, .overlay').on('click', function (ev) {
    ev.preventDefault();

    $('.sidebar .treeview.open:not(.active)').removeClass('open');

    if (viewport().width < 992) {
        $('body').toggleClass('sidemenu-open');
        $('body').removeClass('sidemenu-collapsed');
    } else {
        $('body').toggleClass('sidemenu-collapsed');
        $('body').removeClass('sidemenu-open');
    }

    $('.sidebar').on(
        'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd',
        function (ev) {
            $(this).webfScrollbar('update');
        }
    );
});

$('.toggle-user-menu').webfDropdownmenu({
    behavior: 'menu-toggle',
    menu: $('.dropdown-menu-user'),
    position: {
        my: 'right top',
        at: 'right bottom',
        collision: 'fit none',
        offset: {
            top: -5,
            left: -10
        }
    },
    onOpen: function () {
        $(this).addClass('open');
    },
    onClose: function () {
        $(this).removeClass('open');
    },
    durationAnimationOpen: 340,
    durationAnimationClose: 120,
});

$('.sidebar .treeview > a').on('click', function (ev) {
    ev.preventDefault();

    // Ouverture du sous menu, l'ajout des classes active du CSS
    if (viewport().width < 992) {
        if ($('body').hasClass('sidemenu-open')) {
            $(this).parent().toggleClass('open');
        } else {
            $(this).parent().addClass('open');
        }

        $('body').addClass('sidemenu-open');
    } else {
        if ($('body').hasClass('sidemenu-collapsed')) {
            $(this).parent().addClass('open');
        } else {
            $(this).parent().toggleClass('open');
        }

        $('body').removeClass('sidemenu-collapsed');
    }

    // Scroll vers le sous-menu si besoin
    var $treeview = $(this).parent();
    $('.sidebar').on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function (ev) {
        $(this).webfScrollbar('update');

        var fitToTop =
                $treeview.offset().top -
                $('.sidebar-menu').offset().top,
            height = $treeview.height(),
            sidebarHeight = $(this).height(),
            fitToBottom = fitToTop - sidebarHeight + height;

        if ($(this).webfScrollbar('scrollTop') > fitToTop) {
            // On a cliqué sur un élément qui dépasse par le haut, on le fait redescendre
            $(this).webfScrollbar('scrollTop', fitToTop);
        } else if ($(this).webfScrollbar('scrollTop') < fitToBottom) {
            // On a cliqué sur un élément qui, une fois déplié, dépasse par le bas
            // On le fait remonter en limitant pour ne pas qu'il dépasse par le haut (Math.min)
            $(this).webfScrollbar('scrollTop', Math.min(fitToTop, fitToBottom));
        }

        $(this).off(ev);
    });
});

// Scroll vers le module actif
var $activeModule = $('.sidebar-menu > .active');
if ($activeModule[0]) {
    $('.sidebar').webfScrollbar(
        'scrollTop',
        $activeModule.offset().top - $('.sidebar-menu').offset().top
    );
}

/* Clic sur les erreurs */

$(document).on('click', '.error > ul > li', function () {
    if ($(this).prop('id')) {
        var $field = $('#' + $(this).prop('id').split('error_')[1]);

        if ($field[0]) {
            while (!$field.is(':visible')) {
                $field = $field.parent();
            }

            $('html,body').animate({scrollTop: $field.offset().top - heightHeader}, 730);
        }
    }
});

/*************************************************************\
    Gestion du sumbit et des sorties de page intempestives
\*************************************************************/

$.fn.SmartSubmit = function (options) {
    window.dontLeave = false;

    options = options || {};

    var msgBeforeUnload = webf.isUndefined(options.onBeforeUnload)
        ? false
        : options.onBeforeUnload;
    var preventDblClick = webf.isUndefined(options.preventDblClick)
        ? true
        : options.preventDblClick;
    var shortcut = webf.isUndefined(options.shortcut)
        ? false
        : options.shortcut;
    var delay = webf.isUndefined(options.delay) ? 500 : options.delay;
    var onSubmit = webf.isUndefined(options.onSubmit)
        ? webf.noop
        : options.onSubmit;

    var $form = this;
    var initialState;

    if (msgBeforeUnload) {
        window.onbeforeunload = function (ev) {
            if (!initialState || dontLeave || initialState == getState()) {
                return;
            }

            var ev = ev || window.event,
                msg =
                    msgBeforeUnload === true
                        ? 'Vous devriez enregistrer votre travail avant de quitter la page'
                        : msgBeforeUnload;

            if (ev) {
                ev.returnValue = msg;
            }

            return msg;
        };

        webf.setTimeout(function () {
            initialState = getState();
        }, delay);

        function getState() {
            var vals = [];

            $form.find('input, select, textarea').each(function () {
                if (
                    !webf.inArray(
                        $(this).prop('type'),
                        'submit button image'.split(' ')
                    )
                ) {
                    var value = $(this).val();

                    if (
                        webf.inArray(
                            $(this).prop('type'),
                            'checkbox'.split(' ')
                        )
                    ) {
                        value = $(this).prop('checked') ? 1 : 0;
                    }

                    if (webf.isArray(value)) {
                        value = value.join(',');
                    }

                    vals.push(value);
                }
            });

            return hex_md5(vals.join(''));
        }
    }

    var submitted = false;
    $form.on('submit', function (ev) {
        if (msgBeforeUnload) {
            initialState = getState();
        }

        if (preventDblClick) {
            if (!submitted) {
                submitted = true;
            } else {
                ev.preventDefault();
            }
        }

        return onSubmit.call($form, ev);
    });

    if (shortcut) {
        $(document).webfShortcut({
            keys: shortcut === true ? 'Mod + S' : shortcut,
            fn: function () {
                // Pour les navigateurs n'implémentant pas reportValidity, le polyfill va simuler un click sur le bouton
                // du submit, il est donc inutile de faire le submit une deuxième fois.
                if ($form[0].reportValidity()) {
                    $form.trigger('submit');
                }
                return false;
            },
        });
    }
};

if (!HTMLFormElement.prototype.reportValidity) {
    HTMLFormElement.prototype.reportValidity = function () {
        var submitButtons = this.querySelectorAll(
            'button, input[type=submit]'
        );

        for (var i = 0; i < submitButtons.length; i++) {
            if (submitButtons[i].type === 'submit') {
                submitButtons[i].click();
                return false;
            }
        }
    };
}

$.fn.toggleRequired = function (required) {
    required = webf.isUndefined(required) ? true : required;

    this.prop('required', required);
    if (this.find('input').length) {
        this.find('input').prop('required', required);
    }

    if (required) {
        this.closest('.form-group, .row')
            .find('.control-label')
            .addClass('required');
    } else {
        this.closest('.form-group, .row')
            .find('.control-label')
            .removeClass('required');
    }

    return this;
};

$('.with-addon').each(function (key, elem) {
    var textAddon = $(this).data('text-addon');

    $(this)
        .closest('.input-group')
        .find('.input-group-addon')
        .webfTooltip({
            content: textAddon,
            tooltipClass: 'smart-tooltip tooltip-up',
            position: {
                offset: {
                    top: -5,
                },
            },
        });
});
